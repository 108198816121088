<template>
  <div class="pc_page_container_all">
    <div id="p_brand_title">品 牌 M V</div>
    <VideoDiv :item="videoItem" />
  </div>
</template>

<script>
import { Options, Vue } from "vue-class-component";
import VideoDiv from "./SubDiv/VideoDiv.vue";

@Options({
  components: { VideoDiv },
  data() {
    return {
      videoItem: {
        type: "video",
        title: "万非主题曲",
        videoURL:
          "https://websiteimg.gnso.cn/wanfei-offical/brand/wanfei.mp4",
        poster:
          "https://websiteimg.gnso.cn/wanfei-offical/brand/cover.png",
        words: ["万非，就是芦荟！", "万非，又不只是芦荟！"],
      },
    };
  },
})
export default class Recruitment extends Vue {}
</script>

<style scoped lang="scss">
@import url("../../../less/pc/page.less");
@import url("../../../less/pc/Brand/index.less");

#p_brand_page_content {
  display: flex;
}

</style>