export const kJoinusPageContentInfo = {
    header: {
        title: "加入万非",
        content: [
            "这是一个芦荟品牌成长的故事，",
            "也是一个女人智慧成长的故事，",
            "更是百万“归蜜”对爱与梦想的追求之路。",
            "我们相信什么就会遇见什么，我们看到的世界不是万物本身，而是我们自己!",
            "——所以，万非！",
        ],
    },
    content: [
        {
            type: 'img_list',
            children: [
                "https://websiteimg.gnso.cn/wanfei-offical/joinus/1.jpg",
                "https://websiteimg.gnso.cn/wanfei-offical/joinus/2.png",
                "https://websiteimg.gnso.cn/wanfei-offical/joinus/3.jpg",
                "https://websiteimg.gnso.cn/wanfei-offical/joinus/4.jpg",
                "https://websiteimg.gnso.cn/wanfei-offical/joinus/5.jpg",
            ],
        },
    ],
}