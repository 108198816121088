export const kBrandPageContentInfo = {
    data: {
        videoURL:
            "https://websiteimg.gnso.cn/wanfei-offical/brand/wanfei.mp4",
        poster:
            "https://websiteimg.gnso.cn/wanfei-offical/brand/cover.png",
        texts: ["万非，就是芦荟！", "万非，又不只是芦荟！"],

    },
}
